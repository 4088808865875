<template>
  <v-container fluid class="smy_wrapper">
    <v-container fluid class="smy_header">
      <v-row v-if="witnessDashboardConfigs.loading === false && isEmptyObject(witnessDashboardConfigs.configs)">
        <v-col>
          <h2>No Metrics Configured</h2>
          <p>You have no items on your dashboard. You can add summary items to your dashboard by clicking <v-btn x-small icon disabled><v-icon>$riwIconSet_addToDashboard</v-icon></v-btn> on the Events or Intervals views.</p>
        </v-col>
      </v-row>
      <v-row wrap class="smy_filter_section" v-if="witnessDashboardConfigs.loading === false && !isEmptyObject(witnessDashboardConfigs.configs)">
        <v-col class="d-flex">
          <witnessDateFilter/>
          <v-btn class="ml-4 white--text" :disabled="reportLoading" rounded color="warning" @click="clickGeneratePDF">
            <v-progress-circular indeterminate size=17 width=2 class="mr-2" v-if="reportLoading"></v-progress-circular>
            <v-icon v-else left>$riwIconSet_pdf</v-icon> Download PDF report
          </v-btn>
        </v-col>
      </v-row>
      <v-row wrap class="smy_filter_section lower" v-if="witnessDashboardConfigs.loading === false && !isEmptyObject(witnessDashboardConfigs.configs)">
        <v-col class="d-flex">
          <v-checkbox class="mb-1 mr-8 mt-6" :indeterminate="subscriptionStatusUnknown" :disabled="subscriptionStatusUnknown" v-model="subscribedCheckboxValue" hide-details label="Subscribe to monthly reports"></v-checkbox>
          <v-switch row dense class="mr-8 mt-6" v-model="toggleShowDescription" label="Show Description" flat inset hide-details></v-switch>
          <v-switch dense class="mt-6" v-model="toggleManagementControls" label="Edit" flat inset hide-details></v-switch>
          <v-spacer />
          <tips-and-tricks goto="using_the_dashboard" class="mt-5 ml-6"/>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid class="smy_contain pt-8">
    <div v-if="witnessDashboardConfigs.loading === false && !isEmptyObject(witnessDashboardConfigs.configs)">
      <v-expand-transition>
        <div v-if="toggleManagementControls">
          <v-row class="mt-0">
            <v-icon class="ml-10 mt-0">$riwIconSet_dragToRearrange</v-icon><h5 class="mt-4 ml-1">Drag to rearrange</h5>
          </v-row>
        </div>
      </v-expand-transition>
      <draggable v-model="witnessDashboardConfigs.configs" :disabled="!toggleManagementControls" chosenClass="grabbed" dragClass="grabbed" animation=150 easing="cubic-bezier(1, 0, 0, 1)" ghostClass="ghostClass" @end="updateWitnessDashboardConfigs">
        <div v-for="(item, index) in witnessDashboardConfigs.configs" :key="index">
          <witnessSummary :class="toggleManagementControls ? 'grabable' : ''" :name="item.name" :requestDetails="item.requestDetails" :mode="item.mode" @removeClicked="removeMetric(index)" @renameClicked="renameMetric(index)" hideAddButton dashboardView :showManagementControls="toggleManagementControls" :showDescription="toggleShowDescription" :showDragable="toggleManagementControls" :requiresInterval="item.requiresInterval"></witnessSummary>
        </div>
      </draggable>
    </div>
    <v-dialog v-model="edit_dialog" max-width="500px" persistent scrollable>
      <v-form v-model="edit_object_valid" @submit.prevent>
      <v-card>
        <v-card-title>
          <span class="headline">{{ editTitle }}</span>
        </v-card-title>
        <v-card-subtitle class="ml-3 mt-0" v-if="originalIndex !== undefined && witnessDashboardConfigs.configs && Array.isArray(witnessDashboardConfigs.configs) && witnessDashboardConfigs.configs[originalIndex].description">
          Description: <br />
          {{ witnessDashboardConfigs.configs[originalIndex].description }}
        </v-card-subtitle>

        <v-card-text style="height: 100%; overflow-y: auto;">
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                  <v-text-field v-if="edit_dialog" dense v-model="editedName" label="Item Name" :rules="[rules.required, rules.minLength]" outlined autofocus></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <div v-if="edit_processing">
            Processing...<v-progress-circular class="ml-2" indeterminate color="amber"/>
          </div>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="editClose" :disabled="edit_processing">Cancel</v-btn>
          <v-btn color="green accent-3" text @click="editSave" type="submit" :disabled="!edit_object_valid || edit_processing">Save</v-btn>
        </v-card-actions>
      </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="editMetricsConfigDialog" max-width="500px" persistent scrollable>
      <v-form v-model="editMetricsConfigValid" @submit.prevent>
      <v-card>
        <v-card-title>
          <span class="headline">{{ editMetricsConfigTitle }}</span>
        </v-card-title>
        <v-card-text style="height: 100%; overflow-y: auto;">
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                  <v-text-field v-if="editMetricsConfigDialog" dense v-model="editMetricsConfigNewName" label="Metrics Configuration Name" outlined :rules="[rules.required, rules.minLength]" autofocus></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <div v-if="editMetricsConfigProcessing">
            Processing...<v-progress-circular class="ml-2" indeterminate color="amber"/>
          </div>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="clickCloseEditMetricsConfig" :disabled="editMetricsConfigProcessing">Cancel</v-btn>
          <v-btn color="green accent-3" text @click="clickSaveEditMetricsConfig" type="submit" :disabled="!editMetricsConfigValid || editMetricsConfigProcessing">Save</v-btn>
        </v-card-actions>
      </v-card>
      </v-form>
    </v-dialog>    
  </v-container>
  </v-container>
</template>

<script>

import witnessDashboardModelViewModel from '@/view-models/witness-dashboard-view-model';

export default witnessDashboardModelViewModel;

</script>
